.App {
text-align: center;
}

html, body{
background-color: #2C2C2C;
}

*{
box-sizing: border-box;
margin: 0;
padding: 0;
}

.wrappermain{
position: relative;
}