.gridflex{
display: flex;
align-items: center;
justify-content: center;
}

.gridtester{
display: grid;
grid-template-columns: 22vw 22vw 22vw 22vw;
text-align: center;
}

.gridtester h2, p{
color: white;
}

.gridtester h2{
font-size: 2vw;
}

.readmore{
display: grid;
grid-template-columns: 22vw 22vw 22vw 22vw;
text-align: center;
}

.hexagrid{
width: 12vw;
height: 12vh;
}

.iconHeader{
color: white;
font-size: 2.5em;
text-align: center;
padding-bottom: 3vw;
}

.tb-1-p{
padding: 10px 0 0 0;
font-size: 1.5vw;
padding: 10%;
display: inline-block;
}

.tb-1-p:hover{
opacity: 0.6;
}

.text{
font-size: 1.2vw;
}

.rule-split{
width: 80%;
margin: 0 auto;
margin-top: 5vh;
text-align: center;
border: 0.5px solid #707070;
}

.dkflag{
width: 20vw;
display: block;
margin: auto;
}

.h2-header{
font-size: 2em;
text-align: center;
color: white;
padding-bottom: 6%;
}

.h2-header2{
font-size: 2em;
text-align: center;
color: white;
padding-bottom: 6%;
display: inline;
}

.img-container{
text-align: center;
display: grid;
grid-template-columns: 1fr 1fr;
padding-left: 10vw;
padding-right: 10vw;
margin-top: 8vw;
}

.header-container{
margin-top: 17.5vw;
max-width: 80vw;
margin-left: 23vw;
position: absolute;
}

.fetch-one{
color: white;
text-align: center;
padding-top: 2%;
font-size: 1.5vw;
}

.fetch-two{
color: white;
text-align: center;
padding-top: 2%;
font-size: 1.5vw;
}

@media screen and (max-width: 800px) {

    .iconHeader{
    font-size: 2em;
    }

    .gridtester h2{
    font-size: 3vw;
    padding-bottom: 10px;
    }

    .text{
    font-size: 12px;
    }

    .tb-1-p{
    font-size: 14px;
    }

    .img-container{
    grid-template-columns: 1fr;
    margin-top: 25vw;
    padding-left: 0vw;
    padding-right: 0vw;
    }
    .dkflag{
    width: 50vw;
    }
    .hexIcons{
    width: 6.5vw;
    padding-top: 10px;
    }
    .infobox{
    margin-bottom: 50px;
    }
}