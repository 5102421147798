.returnbtn{
width: 150px;
height: 40px;
border-radius: 8px;
border: 2px solid #F68300;
background: none;
color: #F68300;
margin-bottom: 30px;
cursor: pointer;
}

.returnbtn:active{
outline: none;
}

.returnbtn:hover{
background-color: #F68300;
color: white;
transition: all 0.2s;
}

.wrapnews{
display: grid;
grid-template-columns: 10% 90%;
}

.content-break{
padding-bottom: 30px;
}

.news-wrap{
width: 45vw;
margin: 0 auto;
padding-bottom: 50px;
}

@media screen and (max-width: 800px) {
    .news-wrap{
    width: 80vw;
}
}