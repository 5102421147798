.center-header{
text-align: center;
font-size: 2.5em;
color: white;
}

.infotxt{
color: white;
}

.pd-main{
text-align: center;
position: relative;
margin-bottom: 50px;
}

.dot {
height: 2vw;
width: 2vw;
border-radius: 50%;
display: inline-block;
position: absolute;
}

.pd-later-first{
width: 40vw;
margin-right: 38.2vw;
}

.pd-later-style{
width: 40vw;
z-index: 200;
margin-right: 38.2vw;
}

.centertxt{
text-align: center;
color: white;
}

.textwrap{
width: 30vw;
position: absolute;
top: 50%;
right: 10%;
background-color: #444;
padding: 30px;
border-radius: 8px;
text-align: center;
}

.p-center{
text-align: center;
color: #F68300;
}

.redtxt{
color: red;
}

.pointer-header{
color :#F68300;
font-size: 2em;
padding-bottom: 20px;
text-align: center;
}

    /* New  */

.dot-mildbrain{
top: 2%;
left: 40.7%;
background-color: #F68300;
z-index: 1;
}

.dot-severebrain{
top: 2%;
left: 42.9%;
background-color: red;
z-index: 1;
}

.dot-nose{
top: 7%;
left: 41.7%;
background-color: #F68300;
}

.dot-coughing{
top: 12.5%;
left: 41.7%;
background-color: #F68300;
z-index: 1;
}

.dot-chest-orange{
top: 26%;
left: 39.8%;
background-color: #F68300;
z-index: 1;
}

.dot-heart-red{
top: 24.5%;
left: 41.8%;
background-color: red;
z-index: 1;
}

.dot-chest-red{
top: 26%;
left: 43.8%;
background-color: red;
z-index: 1;
}

.dot-bloodclot{
top: 68%;
left: 43.6%;
background-color: red;
z-index: 1;
}

.dot-muscles{
top: 34%;
left: 46.3%;
background-color: #F68300;
z-index: 1;
}

.dot-joint{
top: 45%;
left: 46.7%;
background-color: #F68300;
z-index: 1;
}
.le-link{
color: white;
font-size: 10px;
text-align: center;
}


@media screen and (max-width: 900px) {
    .textwrap{
    width: 80vw;
    position: relative;
    top: 0%;
    left: 4.5%;
    margin-bottom: 50px;
    }

    .dot {
     height: 5vw;
     width: 5vw;
    }

    .dot-mildbrain{
    top: 1%;
    left: 71.5%;
    }

    .dot-severebrain{
    top: 1%;
    left: 77%;
    }

    .dot-nose{
    top: 7%;
    left: 74.5%;
    }

    .dot-coughing{
    top: 14%;
    left: 74.5%;
    }

    .dot-chest-orange{
    top: 26%;
    left: 69%;
    }

    .dot-heart-red{
    top: 24.5%;
    left: 74.5%;
    }

    .dot-chest-red{
    top: 26%;
    left: 80%;
    }

    .dot-bloodclot{
    top: 68%;
    left: 78.5%;
    }

    .dot-muscles{
    top: 34%;
    left: 84%;
    }

    .dot-joint{
    top: 45%;
    left: 84.5%;
    }
    
    .pd-later-first{
    width: 80vw;
    margin-right: 3.5vw;
    }
    
    .pd-later-style{
    width: 80vw;
    margin-right: 3.5vw;
    }
}