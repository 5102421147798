.article-wrap{
text-align: center;
background-color: #333;
width: 90%;
margin: 0 auto;
padding-top: 30px;
padding-bottom: 30px;
margin-bottom: 50px;
border-radius: 0 8px 8px;
}

.pre-headtxt{
color: white;
font-size: 40px;
padding-left: 20px;
padding-right: 20px;
margin-bottom: 40px;
padding-top: 40px;
}

.pre-wrapper{
text-align: left;
display: grid;
grid-template-columns: 80% 20%;
background-color: #404040;
width: 70vw;
margin-left: 10.8%;
padding-top: 3%;
padding-bottom: 3%;
border-width: 0 5px 5px 5px; /* top right bottom left */
border-style: solid;
border-color: #444;
margin-bottom: 10px;
margin-top: 10px;
}

.pre-article{
width: 45vw;
}

.pre-wrapper p{
color: white;
font-size: 20px;
}

.pre-wrapper h3{
color: white;
font-size: 30px;
padding-bottom: 20px;
}

.wrapper-one{
border-radius: 25px 25px 0px 0px;
}

.wrapper-last{
border-radius: 0px 0px 25px 25px;
margin-bottom: 50px;
}

.pre-wrapperleft{
text-align: right;
display: grid;
grid-template-columns: 20% 80%;
background-color: #404040;
width: 70vw;
margin-left: 10.8%;
padding-top: 3%;
padding-bottom: 3%;
border-width: 0 5px 5px 5px; /* top right bottom left */
border-style: solid;
border-color: #444;
}

.pre-wrapperleft p{
color: white;
font-size: 20px;
}

.pre-wrapperleft h3{
color: white;
font-size: 30px;
padding-bottom: 20px;
}

/* Working SVG scaling Begin Left  */

.handsanitizeicon svg{
left: 18.8vw;
height: 20vh;
width: 13.5vw;
}

.handsanitizeicon{
padding-bottom: 20vh;
}

.hexIconsleft{
width: 4.5vw;
position: absolute;
padding-top: 12px;
z-index: 20;
}

.washiconleft{
left: 17.5vw;
height: 15vh;
width: 13.5vw;
}

/* Working SVG scaling End */

/* Working SVG scaling Begin Right  */

.hexIconspre{
width: 4.5vw;
position: absolute;
padding-top: 10px;
z-index: 20;
}

.alignicon{
padding-bottom: 20vh;
}

.alignicon svg{
left: 68.8vw;
height: 20vh;
width: 13.5vw;
}

.hexIconsright{
width: 4.5vw;
position: absolute;
padding-top: 12px;
z-index: 20;
}

/* Working SVG scaling EndRight  */

article{
margin: auto;
width: 100%;
}

@media screen and (max-width: 800px) {
    .article-wrap{
        border-radius: 8px;
        width: 100%;
        }
    .pre-wrapper{
        width: 87vw;
        margin: auto;

    }
    .pre-wrapperleft{
        width: 87vw;
        margin: auto;
    }
    .article-wrap{
        margin-bottom: 50px;
    }
    .wrapper-last{
    margin-bottom: 50px;
    }
    .handsanitizeicon svg{
    left: 8vw;
    width: 20vw;
    }
    .alignicon svg{
    left: 70vw;
    width: 20vw;
    }
    .pre-article{
    width: 55vw;
    }
    .pre-article p{
    font-size: 16px;
    }
}