.vaccinewrap{
background-color: #333;
width: 90%;
margin: 0 auto;
padding-top: 30px;
padding-bottom: 30px;
margin-bottom: 50px;
border-radius: 8px;
}

.vaccineheader{
text-align: center;
font-size: 2.5em;
color: white;
}

.abouthr{
width: 40%;
margin: 0 auto;
margin-top: 5vh;
text-align: center;
border: 0.5px solid #707070;
margin-bottom: 3vh;
margin-top: 20px;
}

.snakes{
width: 300px;
height: 300px;
display: inline;
}

.svg-img{
width: 120px;
height: 120px;
}

.vac-pad{
padding-left: 10vw;
padding-right: 10vw;
text-align: center;
}

.vac-pad p{
font-size: 20px;
}

.vactextwrap{
display: flex;
justify-content: center;
align-items: center;
}

.miniheader{
color: white;
font-size: 38px;
text-align: center;
padding-top: 20px;
padding-bottom: 30px;
}

.astraz{
height: 200px;
margin-bottom: 100px;
}

.moderna{
width: 300px;
margin-bottom: 100px;
}

.vac-p{
text-align: left;
}

.vac-h3{
color: white;
font-size: 25px;
}

.h3-wrap{
background-color: #444;
margin-left: 150px;
border-radius: 25px 25px 0 0;
padding-top: 10px;
padding-bottom: 10px;
}

/* New grid test */

.vaccinegrid{
display: grid;
grid-template-columns: 1fr 1fr;
}

.vc-opacity{
opacity: 0.15;
}

.vacgrid-small{
display: grid;
grid-template-columns: 1fr 1fr;
margin-left: 150px;
border-radius: 0 0 25px 25px;
background-color: #444;
}

.vacgrid-small div{
padding: 20px;
}

.vacgrid-small svg{
width: 100px;
height: 100px;
}

.vac-linkbox{
text-align: center;
}

.vac-link{
color: white;
font-size: 10px;
}

.vac-hr{
width: 70%;
margin: 0 auto;
margin-top: 5vh;
text-align: center;
border: 0.5px solid #707070;
margin-bottom: 7vh;
margin-top: 20px;
}

.az-v{
margin-bottom: 100px;
}

@media screen and (max-width: 1000px) {
    .vacgrid-small{
    margin-left: 40px;
    }
    .h3-wrap{
    margin-left: 40px;
    }
}

@media screen and (max-width: 800px) {
    .vaccinewrap{
    width: 100%;
    }
    .vaccinegrid{
    grid-template-columns: 1fr;
    grid-gap: 50px;
    }
    .vacgrid-small{
    margin-left: 0px;
    }
    .h3-wrap{
    margin-left: 0px;
    }
    .vac-hr{
    width: 90%;
    }
}