.hero-img{
width: 99vw;
height: 20vw;
object-fit: cover;
}

.hero-headtxt{
color: white;
font-size: 3vw;
}

.hero-txt{
color: white;
position: absolute;
top: 0%;
left: 35%;
margin-top: 7%;
}

.header-para{
font-size: 1.8vw;
}

.orange{
color: #F68300;
}


.cover-img{
position: absolute;
margin-top: 30px;
margin-left: 10px;
top: 0%;
}

.container a{
text-decoration: none;
}

.container ul {
margin:0;
padding:0;
display:flex;
position: absolute;
top: 50%;
left:50%;
transform: translate(-50%, -50%);
}

.container ul li {
list-style:none;
margin: 0 15px;
cursor: pointer;
position: absolute;
}

.container ul li {
position: relative;
display: block;
text-align: center;
width: 13vw;
height: 6.5vh;
line-height: 6vh;
background: #171515;
border-radius: 40px;
font-size: 1.5vw;
color: #666;
transition: .5s;
}

.container ul li:before {
content: '';
position: absolute;
top:0;
left:0;
width:100%;
height:100%;
border-radius:40px;
background: #F68300;
transition: .5s;
transform: scale(.9);
z-index: -1;
}

.container ul li:hover:before {
transform: scale(1.15);
box-shadow: 0 0 15px #F68300;
filter: blur(3px);
}

.container ul li:hover {
color: #ffa502;
box-shadow: 0 0 15px #F68300;
text-shadow: 0 0 15px #F68300;
}

.container{
position: absolute;
top: 9.5vw;
left: 50vw;
margin-top: 7%;
}

.navbar-text{
font-size: 1.4vw;
}

.icons{
width: 1.5vw;
vertical-align: middle;
display: inline-block;
margin-right: 1vw;
margin-top: -6px;
}

@media screen and (max-width: 800px) {
    .hero-img{
    height: 30vw;
    }
    .hero-headtxt{
    font-size: 4vw;
    }
    .header-para{
    font-size: 2.8vw;
    }
    .hero-txt{
    margin-top: 12%;
    }
}