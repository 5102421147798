.notfound{
text-align: center;
margin: 0 auto;
padding-bottom: 200px;
}

.notfound p{
font-size: 24px;
padding-bottom: 50px;
}

.btnreturn{
width: 350px;
height: 50px;
border-radius: 40px;
font-size: 24px;
border: 2px solid #171515;
background: #171515;
color: white;
cursor: pointer;
margin: 0 auto;
text-decoration: none;
transition: .5s;
}

.btnreturn:hover{
color: #F68300;
}