.pd-wrap{
background-color: #333;
width: 90%;
margin: 0 auto;
padding-top: 30px;
padding-bottom: 30px;
margin-bottom: 50px;
border-radius: 0 8px 8px;
}

.txtcenter{
text-align: center;
padding: 20px;
padding-bottom: 20px;
font-size: 2.5em;
color: white;
display: inline;
}

.h1-hr{
width: 40%;
margin: 0 auto;
margin-top: 5vh;
text-align: center;
border: 0.5px solid #707070;
margin-bottom: 7vh;
margin-top: 20px;
}

.infotxt{
color: white;
}

.pd-main{
text-align: center;
position: relative;
margin-bottom: 50px;
}

.dot {
height: 2vw;
width: 2vw;
border-radius: 50%;
display: inline-block;
position: absolute;
}

.red{
color: red;
}

.dot-cough{
top: 13%;
left: 42.8%;
background-color: #F68300;
}

.dot-badcough{
top: 13%;
left: 40.8%;
background-color: red;
z-index: 1;
}

.dot-heart{
top: 24.5%;
left: 41.8%;
background-color: red;
z-index: 1;
}

.dot-lungorange{
top: 26%;
left: 43.8%;
background-color: #F68300;
z-index: 1;
}

.dot-lungred{
top: 26%;
left: 39.8%;
background-color: red;
z-index: 1;
}

.dot-headfever{
top: 4%;
left: 40.7%;
background-color: #F68300;
z-index: 1;
}

.dot-headache{
top: 4%;
left: 42.7%;
background-color: #F68300;
z-index: 1;
}

.dot-kidney{
top: 38%;
left: 43.4%;
background-color: red;
z-index: 1;
}

.dot-stomach{
top: 41%;
left: 41.4%;
background-color: #F68300;
z-index: 1;
}

.dot-muscle{
top: 34%;
left: 41.4%;
background-color: #F68300;
z-index: 1;
}

.pd-first{
width: 40vw;
margin-right: 38.2vw;
}

.pd-style{
width: 40vw;
z-index: 200;
margin-right: 38.2vw;
}

.centertxt{
text-align: center;
color: white;
}

.textwrapper{
width: 30vw;
position: absolute;
top: 50%;
right: 10%;
background-color: #444;
padding: 30px;
border-radius: 8px;
}

.pointerheader{
color :#F68300;
font-size: 2em;
padding-bottom: 20px;
}

@media screen and (max-width: 900px) {
    .textwrapper{
    width: 80vw;
    position: relative;
    top: 0%;
    left: 4.5%;
    margin-bottom: 50px;
    }
    .dot {
    height: 5vw !important;
    width: 5vw !important;
    }
    .dot-cough{
    top: 13%;
    left: 76.5%;
    background-color: #F68300;
    }
    .dot-badcough{
    top: 13%;
    left: 71%;
    background-color: red;
    z-index: 1;
    }
    .dot-heart{
    top: 24.5%;
    left: 74%;
    background-color: red;
    z-index: 1;
    }
    .dot-lungorange{
    top: 26%;
    left: 79%;
    background-color: #F68300;
    z-index: 1;
    }
    .dot-lungred{
    top: 26%;
    left: 69%;
    background-color: red;
    z-index: 1;
    }
    .dot-headfever{
    top: 4%;
    left: 71.5%;
    background-color: #F68300;
    z-index: 1;
    }
    .dot-headache{
    top: 4%;
    left: 76.5%;
    background-color: #F68300;
    z-index: 1;
    }
    .dot-kidney{
    top: 38%;
    left: 43.4%;
    background-color: red;
    z-index: 1;
    }
    .dot-stomach{
    top: 41%;
    left: 74%;
    background-color: #F68300;
    z-index: 1;
    }
    .dot-muscle{
    top: 34%;
    left: 74%;
    background-color: #F68300;
    z-index: 1;
    }
    .pd-first{
    width: 80vw;
    margin-right: 3.5vw;
    }
    .pd-style{
    width: 80vw;
    margin-right: 3.5vw;
    }
}