svg{
display: inline-block;
}

.aboutwrap{
background-color: #333;
width: 90%;
margin: 0 auto;
padding-top: 30px;
padding-bottom: 30px;
margin-bottom: 50px;
border-radius: 0 8px 8px;
}

.aboutheader{
text-align: center;
font-size: 2.5em;
color: white;
}

.abouthr{
width: 40%;
margin: 0 auto;
margin-top: 5vh;
text-align: center;
border: 0.5px solid #707070;
margin-bottom: 7vh;
margin-top: 20px;
}

.about-p{
color: white;
font-size: 20px;
text-align: center;
}

.abso-box{
position: relative;
padding-top: 300px;
padding-bottom: 300px;
margin-left:auto;
margin-right:auto;
width:500px;
}

.covidball{
width: 60%;
height: 60%;
display: block;
margin-left: auto;
margin-right: auto;
}


.icon-style{
width: 100px;
height: 100px;
position: absolute;
top: 0;
right: 0;
z-index: 102;
}

.txt-box{
padding-left: 10px;
padding-right: 30px;
padding-top: 40px;
width: 300px;
height: 200px;
background-color: #444;
color: white;
border-radius: 150px;
overflow: hidden;
position: absolute;
display: inline;
opacity: 0;
visibility: hidden;
z-index: 2;
}

.txt-box p{
z-index: 2;
font-size: 18px;
}

.txtboxright{
padding-left: 150px;
}

.txtboxleft{
padding-right: 145px;
padding-left: 50px;
}

.mainhover{
margin-top: 10px;
position: absolute;
z-index: 2;
}

.rightbox{
margin-left: -130px;
margin-top: -50px;
}

.leftbox{
top: -50%;
right: -25%;
margin-top: -50px;
margin-right: -30px;
}

.mainhover:hover .txt-box{
display: inline;
position: absolute;
opacity: 1;
visibility: visible;
width: 500px;
transition: width .5s;
z-index: 2;
}

/* Bat box */

.batplacement{
top: 43.5%;
right: -5%;
}

.batplacement:hover{
z-index: 202;
}

/* Bed box */

.bedplacement{
top: 43.5%;
right: 85%;
}

.bedplacement:hover{
z-index: 202;
}

/* China box */

.chinaplacement{
top: 17.5%;
right: 39.5%;
}

.chinaplacement:hover{
z-index: 202;
}

/* Crown box */

.crownplacement{
top: 24%;
right: 74%;
}

.crownplacement:hover{
z-index: 202;
}

/* Question box */

.virusesplacement{
top: 62%;
right: 74%;
}

.virusesplacement:hover{
z-index: 202;
}

/* Lungs box */

.lungsplacement{
top: 69%;
right: 39.5%;
}

/* Petri box */

.petriplacement{
top: 62%;
right: 7%;
}

/* Variant box */

.variantplacement{
top: 24%;
right: 6%;
}


@media screen and (max-width: 900px) {

.covidball{
width: 40%;
height: 40%;
}

.mainhover:hover .txt-box{
width: 400px;
}

.txt-box p{
font-size: 14px;
}

/* Bat box */

.batplacement{
top: 42.5%;
right: 7%;
}

.batplacement:hover{
z-index: 202;
}

/* Bed box */

.bedplacement{
top: 43.5%;
right: 75%;
}

/* China box */

.chinaplacement{
top: 20.5%;
right: 39.5%;
}


/* Crown box */

.crownplacement{
top: 28%;
right: 64%;
}

.crownplacement:hover{
z-index: 202;
}

/* Question box */

.virusesplacement{
top: 58%;
right: 64%;
}

/* Lungs box */

.lungsplacement{
top: 63%;
right: 39.5%;
}

/* Petri box */

.petriplacement{
top: 58%;
right: 17%;
}

/* Variant box */

.variantplacement{
top: 28%;
right: 15%;
}
}

@media screen and (max-width: 700px) {

.aboutwrap{
width: 100%;
border-radius: 8px;
}

.covidball{
width: 20%;
height: 20%;
margin-left: 39%;
}

.icon-style{
width: 70px;
height: 70px;
}

.rightbox{
margin-left: -110px;
margin-top: -60px;
}

.leftbox{
margin-top: -60px;
margin-right: -30px;
}

.txtboxright{
padding-left: 120px;
}

.txtboxleft{
padding-right: 100px;
padding-left: 50px;
}

.mainhover:hover .txt-box{
width: 300px;
}

.txt-box p{
font-size: 13px;
}

/* Bat box */

.batplacement{
top: 44%;
right: 20%;
}

/* Bed box */

.bedplacement{
top: 44%;
right: 68%;
}

/* China box */

.chinaplacement{
top: 28%;
right: 44%;
}


/* Crown box */

.crownplacement{
top: 32%;
right: 61%;
}

/* Question box */

.virusesplacement{
top: 55%;
right: 61%;
}

/* Lungs box */

.lungsplacement{
top: 59%;
right: 44%;
}

/* Petri box */

.petriplacement{
top: 55%;
right: 27%;
}

/* Variant box */

.variantplacement{
top: 32%;
right: 27%;
}
}